import { Divider } from 'antd'
import moment from 'moment'
import React, { FC, Fragment, ReactElement } from 'react'
import { BadgeTypes } from '../../../enums/badgeTypes'
import { CustomerModel } from '../../../models/Customer/customer.model'
import { displayDash } from '../../utils/displayDash'
import { formatDate } from '../../utils/formatDate'

interface VerificationdetailsProps {
    customer: CustomerModel;
    renderDivider: ReactElement;
    isMobile: boolean;
    isApprovedUser?: boolean;
    isRejectionReasonAvailable?: boolean;
    isRejectedUser?: boolean;
 }

const Verificationdetails: FC<VerificationdetailsProps> = ({customer, renderDivider, isMobile, isRejectionReasonAvailable, isRejectedUser, isApprovedUser}) => {
    return (
        <>
        {!isMobile && <div className="subheader">Verifications details</div>}
        {(isRejectionReasonAvailable && !isApprovedUser) && <>
        <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Rejected By</p>
            <p className="kyc-value">{customer?.rejectedBy?.firstName} {customer?.rejectedBy?.lastName}</p>
        </div>
        {renderDivider}
        <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Rejected On</p>
            <p className="kyc-value">{displayDash(customer?.rejectedAt && moment(customer?.rejectedAt)?.format("DD MMM YYYY"))}</p>
        </div>
        {renderDivider}
        <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Rejected reason</p>
            <p className="kyc-value">
            {customer?.rejectionReasons?.map((reason, index) => (
            <Fragment key={index}>
                {`${reason?.label}`}
                {index !== (customer?.rejectionReasons?.length ?? 0) - 1 && ', '}
            </Fragment>
            ))}
            </p>
        </div>
        {renderDivider}
        <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Comment</p>
            <p className="kyc-value">{displayDash(customer?.rejectedReason)}</p>
        </div>
        {renderDivider}
        <Divider /> </>}
        {isApprovedUser && <>
            <div className="approve-customer-third-party-details-card-item">
              <p className="approve-customer-third-party-details-card-item-title">Approved by</p>
              <p className="kyc-value">{displayDash(customer?.onboardingDetail?.adminName)}</p>
            </div>
            <div className="approve-customer-third-party-details-card-item">
              <p className="approve-customer-third-party-details-card-item-title">Approved on</p>
              <p className="kyc-value">
                {customer?.onboardingDetail?.onboardedAt &&
                    formatDate(customer?.onboardingDetail?.onboardedAt)}
              </p>
            </div>
            <div className="approve-customer-third-party-details-card-item">
              <p className="approve-customer-third-party-details-card-item-title">Comments</p>
              <p className="kyc-value">{displayDash(customer?.onboardingDetail?.adminComments)}</p>
            </div>
            <Divider /></>}
    
        </>
    )
}

export default Verificationdetails