import { Divider } from 'antd'
import React, { FC, Fragment, ReactElement } from 'react'
import { BadgeTypesDefinition } from '../../../definitions/badgeTypesDefinition'
import { BadgeTypes } from '../../../enums/badgeTypes'
import { CustomerModel } from '../../../models/Customer/customer.model'
import { displayDash } from '../../utils/displayDash'
import ButtonComponent from '../ButtonComponent'
import approvedIcon from "../../../assets/images/approved.png"
import pendingIcon from "../../../assets/images/pending.png"
import rejectedIcon from "../../../assets/images/rejected.png"

interface KycdetailsProps { 
    customer: CustomerModel;
    renderDivider?: ReactElement;
    isMobile: boolean;
    KYCStatus: BadgeTypes.APPROVED | BadgeTypes.FAILED | BadgeTypes.KYC_PENDING;
    resetModal: () => void;
}

interface ManualKycDetailsProps { 
    renderDivider?: ReactElement;
    isMobile: boolean;
    kycVerification: () => void;
}

export const Kycdetails: FC<KycdetailsProps> = ({customer, renderDivider, isMobile, KYCStatus, resetModal}) => {
return (
<Fragment>
    {!isMobile &&  <div className="subheader">KYC details</div>}
    <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Auto KYC</p>
    <p className={`kyc-value ${KYCStatus === BadgeTypes.FAILED ? "negative" : KYCStatus === BadgeTypes.KYC_PENDING ? "pending" : "positive"}`}>
        {BadgeTypesDefinition[KYCStatus as BadgeTypes]} 
        <img src={KYCStatus === BadgeTypes.FAILED ? rejectedIcon : KYCStatus === BadgeTypes.KYC_PENDING ? pendingIcon : approvedIcon} className="status-icon" alt="status-icon" /> 
    </p>
    </div>
    {renderDivider}
    <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Verification Date</p>
    <p className="kyc-value">{customer?.kycLastVerifiedAt}</p>
    </div>
    {renderDivider}
    <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">KYC rejection reason</p>
    <p className="kyc-value text-right">{displayDash(customer?.onboardingDetail?.digifiedComments)}</p>
    </div>
    {renderDivider}
    {
    customer?.status === BadgeTypes.PENDING_APPROVAL &&
    <div className="approve-customer-third-party-details-card-item">
        <p className="approve-customer-third-party-details-card-item-title"></p>
        <p className={`kyc-value ${isMobile && "button"}`}>
        <ButtonComponent
            size={isMobile ? "small" : "medium"}
            type="primary"
            additionalTypes={"primary"}
            className="edit-detail"
            onClick={resetModal}
        >
            Reset KYC verification
        </ButtonComponent>
        </p>
    </div>
    }
    <Divider/>
</Fragment>
)
}

export const ManualKycDetails: FC<ManualKycDetailsProps> = ({renderDivider, isMobile, kycVerification}) => {
    return (
        <>
        {!isMobile && <div className="subheader">KYC details</div>}
        <div className="approve-customer-third-party-details-card-item">
            {!isMobile && <p className="approve-customer-third-party-details-card-item-title">Auto KYC</p>}
            <p className={`kyc-value ${isMobile && "button"}`}>
                <ButtonComponent
                    size={isMobile ? "small" : "medium"}
                    type="primary"
                    additionalTypes={"primary"}
                    className="edit-detail"
                    onClick={kycVerification}
                >
                    Manually verify KYC
                </ButtonComponent>
                </p>
            </div>
            {renderDivider}
            <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Verification Date</p>
            <p className="kyc-value">-</p>
        </div>
        {renderDivider}
        {!isMobile && <Divider/>}
        </>
    )
}