import React, { FC, useState } from "react";
import CardComponent from "../../../../../../shared/components/CardComponent";
import "./approveCustomerThirdPartyDetailsCard.scss";
import { CustomerModel } from "../../../../../../models/Customer/customer.model";
import IconComponent from "../../../../../../shared/components/IconComponent";
import CustomerDetailsForm from "../../../CustomerDetailsForm/index"
import { BadgeTypes } from "../../../../../../enums/badgeTypes";
import {LocalStorage} from "../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../models/User/user.model";
import moment from "moment";
import { Collapse, Divider } from "antd";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import { useParams } from "react-router-dom";
import { mobileScreenWidth } from "../../../../../../enums/constants";
import userReapply from "../../../../../../assets/images/user-reapply.png"
import { ReapplicationTypes } from "../../../../../../enums/reapplicationTypes";
import Customerdetails from "../../../../../../shared/components/Customerdetails";
import { Kycdetails, ManualKycDetails } from "../../../../../../shared/components/Kycdetails";
import Verificationdetails from "../../../../../../shared/components/Verificationdetails";
const { Panel } = Collapse;
interface ApproveCustomerThirdPartyDetailsCardProps {
  loading: boolean;
  customer: CustomerModel | undefined;
  handleRefresh: () => void;
  detailsExpanded?: boolean;
  setDetailsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

const ApproveCustomerThirdPartyDetailsCard: FC<ApproveCustomerThirdPartyDetailsCardProps> = (props) => {
  const {loading, customer, handleRefresh, detailsExpanded, setDetailsExpanded} = props;
  const { resetKycRetry, loading: resetKycRetryLoading, kycVerification } = CustomerService()
  const { customerId } = useParams();
  const [editCustomerDetails, setEditCustomerDetails] = useState(false)
  const [resetModalVisible, setResetModalVisible] = useState(false)
  const isRejected = customer?.status === BadgeTypes.REJECTED
  const isApprovedUser = customer?.status === BadgeTypes.APPROVED
  const KYCStatus = customer?.kycStatus
  const user = LocalStorage.getItem('USER') as UserModel
  const isMobile = window.innerWidth <= mobileScreenWidth;
  const renderDivider = isMobile ? <Divider/> : <></>
  const isRejectionReasonAvailable = (customer?.rejectionReasons?.length ?? 0) > 0
  const hasUserReapplied: boolean = !!customer?.customerReapplyReason;
  const isPhoneUser = customer?.isPhoneOnlyUser;

  const handleSubmit = () => customerId && resetKycRetry(customerId, true).then(()=>{ setResetModalVisible(false); handleRefresh() })
  return (
    <div className={`third-party-card ${detailsExpanded && "max-height"} ${(hasUserReapplied && !isMobile) && "reapplied"}`}>
      <CustomerDetailsForm
        visible={editCustomerDetails}
        closeHandler={() => setEditCustomerDetails(false)} 
        data={customer}
        successHandler={ () => {
            handleRefresh()
            setEditCustomerDetails(false)
          }
        }
      />
      <ModalComponent
        type="alert"
        className="reset-retry-count-alert"
        alert={{
          title: "Are you sure you want to reset the customer KYC verification?",
          description: "This will allow the customers to retry account verification process again through their mobile application",
          buttonType: "primary"
        }}
        visible={resetModalVisible}
        closeHandler={()=>setResetModalVisible(false)}
        successProps={{
          title: "Confirm",
          clickHandler: handleSubmit,
          loading: resetKycRetryLoading
        }}
        cancelProps={{
          title: "Close",
          clickHandler: ()=>setResetModalVisible(false),
        }}
      />
      {(hasUserReapplied && !isMobile) &&
        <CardComponent className="reapplied">
            <>
              <div className="reapplication-container">
                <div className="reappy-header">
                  <div className="icon"><img src={userReapply} alt="" /></div>
                  <div className="title">Reapplication</div>
                </div>
                <div className="reapply-content">{
                  customer?.customerReapplyReason === ReapplicationTypes.KYC_RESET ? <div>Customer reapplied after <strong>KYC reset</strong></div> : 
                  customer?.customerReapplyReason === ReapplicationTypes.REAPPLY_TO_PHONE ? <div>Customer reapplied for <strong>phone account</strong> after being <strong>rejected</strong> for a <strong>basic account</strong></div> :
                  customer?.customerReapplyReason === ReapplicationTypes.ADDITIONAL_DOCUMENT && isPhoneUser ? <div>Customer reapplied to <strong>phone only account</strong> after <strong>uploading documents</strong></div> : <div>Customer reapplied to <strong>basic account</strong> after <strong>uploading documents</strong></div> 
                }</div>
              </div>
              <Divider className="reapply-divider" />
            </>
        </CardComponent>
      }
      <CardComponent
        className={`approve-customer-third-party-details-card ${detailsExpanded && "max-height"} ${hasUserReapplied && 'no-border'}`}
        loading={loading}
        icon={!isRejected && user?.role !== BadgeTypes.VIEWER && <IconComponent name="icon-edit" onClick={() => {
          setEditCustomerDetails(true)
        }
        } />}
      >
        <div className={`approve-customer-third-party-details-card__content ${detailsExpanded && "max-height"}`}>
          {!isMobile &&
          <>
            {KYCStatus && !customer?.allowManualKyc && <Kycdetails customer={customer!} renderDivider={renderDivider} isMobile={isMobile} KYCStatus={KYCStatus} resetModal={() => setResetModalVisible(true)} />}
            {customer?.allowManualKyc && <ManualKycDetails renderDivider={renderDivider} isMobile={isMobile} kycVerification={() => customer?.id && kycVerification(customer?.id?.toString()).then(() => handleRefresh())} />}
            {(isRejectionReasonAvailable && !isApprovedUser) && <Verificationdetails isMobile={isMobile} renderDivider={renderDivider} customer={customer!} isApprovedUser={isApprovedUser} isRejectionReasonAvailable={isRejectionReasonAvailable} isRejectedUser={isRejected}  /> }
            <Customerdetails isMobile={isMobile} detailsExpanded={() => setDetailsExpanded((state)=>!state)} customer={customer!} renderDivider={renderDivider} isApprovedUser={isApprovedUser} />
          </>}
          {isMobile &&
        <Collapse
          accordion={false}
          expandIconPosition="right"
          className="accordion-panel-container"
          defaultActiveKey={['manual-kyc', 'kyc-details', 'verification-details', 'customer-details']}
        >
            {customer?.allowManualKyc &&
            <Panel header="KYC details" key="manual-kyc" className="accordion-panel">
              <ManualKycDetails renderDivider={renderDivider} isMobile={isMobile} kycVerification={() => customer?.id && kycVerification(customer?.id?.toString()).then(() => handleRefresh())} />
            </Panel>}
            {KYCStatus && !customer?.allowManualKyc &&
            <Panel header="KYC details" key="kyc-details" className="accordion-panel">
              <Kycdetails customer={customer!} renderDivider={renderDivider} isMobile={isMobile} KYCStatus={KYCStatus} resetModal={() => setResetModalVisible(true)} />
            </Panel>}
            {(isRejectionReasonAvailable && !isApprovedUser) &&
            <Panel header="Verification details" key="verification-details" className="accordion-panel">
              <Verificationdetails isMobile={isMobile} renderDivider={renderDivider} customer={customer!} isApprovedUser={isApprovedUser} isRejectionReasonAvailable={isRejectionReasonAvailable} isRejectedUser={isRejected} />
            </Panel>}
            <Panel header="Customer details" key="customer-details" className="accordion-panel">
              <Customerdetails isMobile={isMobile} detailsExpanded={() => setDetailsExpanded((state)=>!state)} customer={customer!} renderDivider={renderDivider} isApprovedUser={isApprovedUser} />
            </Panel>
        </Collapse>
      }
        </div>
      </CardComponent>
    </div>
  );
};

export default ApproveCustomerThirdPartyDetailsCard;